<template>
  <form>
    <b-form-select
      v-model="individualKey"
      :options="individualOptions"
      :plain="true"
      @change="handleSelectChange"
      class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0"
    >
      <template slot="first">
        <!-- <option :value="null" disabled>{{ i18n['quick-jump-a-individuals'].tcSelectAnotherIndividual }}</option> -->
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump-conversations-individuals',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-a-individuals': {
            tcSelectAnotherIndividual: 'Select another Individual'
          }
        }
      }
    },
    individualKeyProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      individualKey: this.individualKeyProp
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      educational_units: 'conversations/educational_units',
      instructors: 'conversations/instructors',
      officerToolbarSelected: 'user/officerToolbarSelected',
    }),
    educationalUnitOptions() {
      return this.educational_units.map((el) => {
        return {
          text: el.name,
          value: el.ind_key
        }
      })
    },
    individualOptions() {
      const position0 = {
        value: null,
        text: 'Select an individual', //i18n['quick-jump-a-individuals'].tcSelectAnotherIndividual,
        disabled: true,
        selected: true
      }
      // concatenate the list of educationalUnits and instructors then remove duplicates
      let instructors = this.educational_units.concat(this.instructors)
      let uniqueInstructors = instructors.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.ind_key === thing.ind_key &&
              t.name === thing.name &&
              t.sort_name === thing.sort_name &&
              t.ind_key === thing.ind_key
          )
      )
      let sortedList = uniqueInstructors.sort((a, b) => {
        if (a.sort_name < b.sort_name) return -1
        if (a.sort_name > b.sort_name) return 1
        return 0
      })
      let options = sortedList.map(item => {
        return { value: item.ind_key, text: item.name }
      })
      options.unshift(position0)
      return options
    },
  },
  methods: {
    ...mapActions({
      loadCountryByOFTB: 'conversations/loadCountryByOFTB',
      loadEducationalUnits: 'conversations/loadEducationalUnits',
      loadInstructors: 'conversations/loadInstructors',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        const euPayload = {
          org_key: this.officerToolbarSelected.country_state,
          lang_key: this.demograph.language_key
        }
        await Promise.all([
          this.loadEducationalUnits(euPayload),
          this.loadInstructors(euPayload),
          this.loadCountryByOFTB(euPayload)
        ]).then(() => { })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleSelectChange() {
      await Promise.all([await this.setSelectedIndividualKey(this.individualKey)]).then(
        () => {
          this.$emit('individual_changed')
        }
      )
    }
  }
}
</script>

<style/>